import React, { Fragment, Component } from "react";
import {
  Segment,
  Button,
  Icon,
  Header,
  Divider,
  Transition,
} from "semantic-ui-react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import styled from "styled-components";

import backgroundImagePlaceholder from "images/merc_big_l_blur.jpg";
import backgroundImage from "images/merc_big_l.jpg";

const StyledContainer = styled.div`
  &&& {
    height: 100vh;
    width: 100%;
    position: absolute;
    // top: 100%;
    // padding-top: 10vh;
    padding: 30vh 2em;
    @media only screen and (max-width: 767px) {
      padding-top: 10vh;
    }
  }
`;

class CV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
    this.cvContent = this.cvContent.bind(this);
  }

  componentDidMount() {
    this.setState({ visible: true });
  }

  cvContent() {
    return (
      <Segment
        padded="very"
        compact
        textAlign="center"
        style={{ margin: "0 auto" }}
      >
        <Header
          as="h1"
          style={{ fontSize: "3em", fontWeight: "400", fontFamily: "Dosis" }}
        >
          Curriculum Vitae
        </Header>
        <p>A copy of my CV can be found here</p>
        <Button
          as="a"
          primary
          href="https://s3-eu-west-1.amazonaws.com/adbcv/No+address+1+page+general+CV.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="file pdf outline" style={{ opacity: "1" }} />
          View PDF
        </Button>
        <Divider style={{ marginTop: "1.5em" }} />
        <p>Or you can use my LinkedIn</p>
        <Button
          as="a"
          color="linkedin"
          href="https://www.linkedin.com/in/antondebode/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon name="linkedin" style={{ opacity: "1" }} />
          LinkedIn
        </Button>
      </Segment>
    );
  }

  render() {
    const is_visible = this.state.visible;
    return (
      <Transition
        visible={is_visible}
        animation="fade"
        duration={{ hide: 0, show: 500 }}
      >
        <Fragment>
          <LazyLoadImage
            visibleByDefault
            alt="background-image"
            effect="blur"
            height="100%"
            width="100%"
            style={{
              display: "block",
              width: "100vw",
              height: "100vh",
              objectFit: "cover",
              position: "absolute",
            }}
            placeholderSrc={backgroundImagePlaceholder}
            src={backgroundImage} // use normal <img> attributes as props
            wrapperProps={{ style: { display: "block" } }}
          />
          <StyledContainer>{this.cvContent()}</StyledContainer>
        </Fragment>
      </Transition>
    );
  }
}

export default CV;
