import React from "react";
import Typing from "react-typing-animation";
import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";

import "./introScreen.css";

const clearAnimation = () => {
  try {
    document.getElementById("renderer-wrapper").className = "fade";
  } catch (err) {
    return null;
  }
};

const IntroScreen = () => {
  const history = useHistory();
  return (
    <div
      className="App"
      style={{
        position: "absolute",
        width: "100%",
        height: "100vh",
      }}
      onClick={() => {
        clearAnimation();
        setTimeout(() => history.push("/home"), 1000);
      }}
    >
      <div className="introTextDiv">
        <Typing startDelay={1000} speed={30}>
          <h1 className="textOne">From Aerospace...</h1>
          <h1 className="textTwo">To Software</h1>
        </Typing>
      </div>
      <div className="introButtonDiv">
        <button
          className={"introButton"}
          onClick={() => {
            clearAnimation();
            setTimeout(() => history.push("/home"), 1000);
          }}
          to="/home"
        >
          [ Enter ]
        </button>
      </div>
      <div className="animationCredit">
        Animation inspired by:{" "}
        <a href="https://github.com/jobtalle/" target="_blank">
          <Icon name="github" />
          Jobtalle
        </a>
      </div>
    </div>
  );
};

export default IntroScreen;
