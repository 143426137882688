import React, { Component } from "react";
import portal1 from "images/iotportl1.png";
import portal2 from "images/iotportl2.png";
import portal3 from "images/iotportl3.png";
import portal4 from "images/iotportl4.png";
import portal5 from "images/iotportl5.png";

import {
  Divider,
  Image,
  Grid,
  Transition,
  Visibility,
} from "semantic-ui-react";
import {
  StyledP,
  StyledUL,
  StyledColumn,
  StyledHeaderMain,
  StyledSubHeader,
  StyledBackButton,
  StyledContentHeader,
  StyledCaption,
  StyledContainer,
} from "./StyledComponents";

class XeelasPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom_button: true,
      visible: false,
    };
    this.showBottomButton = this.showBottomButton.bind(this);
    this.hideBottomButton = this.hideBottomButton.bind(this);
  }

  componentWillMount() {
    this.setState(() => ({ bottom_button: false }));
  }

  componentDidMount() {
    this.setState(() => ({ visible: true }));
    window.scrollTo(0, 0);
  }

  showBottomButton() {
    // console.log('showing BB')
    this.setState(() => ({ bottom_button: true }));
  }

  hideBottomButton() {
    // console.log('hiding BB')
    this.setState(() => ({ bottom_button: false }));
  }

  render() {
    const is_visible = this.state.visible;
    return (
      <Transition
        visible={is_visible}
        animation="fade"
        duration={{ hide: 0, show: 500 }}
        style={{ padding: "0px 0px", margin: "0px 0px" }}
      >
        <StyledContainer id="XeelasPortal">
          <StyledHeaderMain>
            Fleet Management Application for Industrial IoT
          </StyledHeaderMain>
          <StyledSubHeader>
            For:{" "}
            <a
              href="https://xeelas.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Xeelas B.V.
            </a>{" "}
          </StyledSubHeader>
          <StyledSubHeader>
            Live:{" "}
            <a
              href="https://portal.xeelas.nl/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              portal.xeelas.nl
            </a>{" "}
          </StyledSubHeader>
          <Visibility
            onOnScreen={this.hideBottomButton}
            onOffScreen={this.showBottomButton}
            once={false}
          >
            {this.state.bottom_button ? <div></div> : <StyledBackButton />}
          </Visibility>

          <Divider />
          <Grid columns={2} divided stackable>
            <Grid.Row>
              <StyledColumn>
                <StyledContentHeader as="h4">What is it?</StyledContentHeader>
                <StyledP>
                  {/* <Image
                    floated="right"
                    src={portal3}
                    style={{
                      marginLeft: "2em",
                      marginBottom: "2%",
                      maxWidth: "50%",
                    }}
                  /> */}
                  Xeelas develops custom IoT solutions for industrial clients.
                  Off-the-shelf IoT doesn’t always cut it when it comes to
                  specialized equipment and rough working conditions. This is
                  where Xeelas comes in: developing hardware, software and
                  service from the ground up, for these extreme cases.
                </StyledP>
                <StyledP>
                  In order to let users set up, configure and monitor their
                  fleets, I developed a web-application for Xeelas’s B2B
                  clients. I was responsible for the entirety of the front end
                  including inception, design, implementation, testing and
                  maintenance. This included meeting clients to formulate their
                  needs into product requirements, and coordinating with backend
                  and devops to ensure the smooth deployment and expansion of
                  the product.
                </StyledP>
                <StyledContentHeader as="h4">
                  What was my role?
                </StyledContentHeader>
                <StyledP>
                  I was the sole developer implementing and maintaining this
                  front-end application.
                </StyledP>
                <StyledContentHeader as="h4">
                  Relevant Technology
                </StyledContentHeader>
                <StyledUL>
                  <li> React.JS </li>
                  <li> React-Testing-Library </li>
                  <li> React Hooks + Context </li>
                </StyledUL>
                <Image size="big" centered rounded src={portal1} />
                <StyledCaption>
                  The Xeelas IoT Portal provides a convenient application to
                  configure and monitor a company’s IoT fleet.
                </StyledCaption>
                <Image size="big" centered rounded src={portal4} />
                <StyledCaption>
                  Assets could be quickly filtered so users could easily
                  overview their assets in real-time.
                </StyledCaption>
              </StyledColumn>
              <StyledColumn>
                <Image size="big" centered rounded src={portal3} />
                <StyledCaption>
                  A common task performed in the portal is to track the movement
                  of assets within given time frames.
                </StyledCaption>
                <Image centered size="big" rounded src={portal2} />
                <StyledCaption>
                  Users could draw and configure geo-fences for interaction with
                  their IoT nodes.
                </StyledCaption>

                <Image size="big" centered rounded src={portal5} />

                <StyledCaption>
                  Fetch past time-series data from whatever date-range you like.
                </StyledCaption>
                <StyledContentHeader as="h4">Highlights</StyledContentHeader>
                <StyledP>
                  <ul>
                    <li>
                      Setup and configure an IoT asset, or connect it to an
                      existing database
                    </li>
                    <li>Search and filter assets based on their properties</li>
                    <li>Generate graphs to report measured data over time</li>
                    <li>Dynamically white-labeled per client</li>
                    <li>Multiple authorization layers</li>
                    <li>Fully responsive</li>
                  </ul>
                </StyledP>
              </StyledColumn>
            </Grid.Row>
          </Grid>
          <Divider />
          <Visibility once={false}>
            {this.state.bottom_button ? <StyledBackButton /> : <div></div>}
          </Visibility>
        </StyledContainer>
      </Transition>
    );
  }
}

export default XeelasPortal;
