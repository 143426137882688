import React, { Component } from "react";
import earlyscanner_image from "images/earlyscanner.jpg";
import {
  Divider,
  Image,
  Grid,
  Transition,
  Visibility,
} from "semantic-ui-react";
import {
  StyledP,
  StyledUL,
  StyledBackButton,
  StyledCaption,
  StyledColumn,
  StyledHeaderMain,
  StyledSubHeader,
  StyledContentHeader,
  StyledContainer,
} from "./StyledComponents";

class SODataExtraction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom_button: false,
      visible: false,
    };
    this.showBottomButton = this.showBottomButton.bind(this);
    this.hideBottomButton = this.hideBottomButton.bind(this);
  }

  componentWillMount() {
    this.setState(() => ({ bottom_button: false }));
  }

  componentDidMount() {
    this.setState(() => ({ visible: true }));
    window.scrollTo(0, 0);
  }

  showBottomButton() {
    this.setState(() => ({ bottom_button: true }));
  }

  hideBottomButton() {
    this.setState(() => ({ bottom_button: false }));
  }

  render() {
    const is_visible = this.state.visible;
    return (
      <Transition
        visible={is_visible}
        animation="fade"
        duration={{ hide: 0, show: 500 }}
        style={{ padding: "0px 0px", margin: "0px 0px" }}
      >
        <StyledContainer id="SODataExtraction">
          <StyledHeaderMain>
            Hardware fleet management and data extraction
          </StyledHeaderMain>
          <StyledSubHeader>For: Shake-on B.V.</StyledSubHeader>
          {/* <StyledSubHeader>
            Sample code:{" "}
            <a
              href="https://github.com/AdeBode/PythonSerialExtractor"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub (Work In Progress)
            </a>
          </StyledSubHeader> */}

          <Visibility
            onOnScreen={this.hideBottomButton}
            onOffScreen={this.showBottomButton}
            once={false}
          >
            {this.state.bottom_button ? <div></div> : <StyledBackButton />}
          </Visibility>

          <Divider />
          <Grid columns={2} divided stackable>
            <Grid.Row>
              <StyledColumn>
                <StyledContentHeader as="h4">What is it?</StyledContentHeader>
                <StyledP>
                  Shake-on B.V. provides mobile NFC scanners intended for the
                  event industry. By scanning these devices with a bracelet or
                  card, a visitor can quickly exchange their information with a
                  booth or event organizer. This makes lead generation much
                  easier for exhibitors, while visitors can recieve relevant
                  information in their email instead of collecting flyers. Event
                  organizers can also use these scanners to register how many
                  attendees passed a certain area or attraction - providing
                  valuable insight into visitor behaviour.
                </StyledP>
                <Image rounded size="large" centered src={earlyscanner_image} />
                <StyledCaption>
                  An example of a prototype NFC scanner based on a Raspberry Pi.
                  Production models are embedded systems.
                </StyledCaption>
                <StyledContentHeader as="h4">Technology</StyledContentHeader>
                <StyledUL>
                  <li>Python</li>
                </StyledUL>
              </StyledColumn>
              <StyledColumn>
                <StyledContentHeader as="h4">
                  What was my role?
                </StyledContentHeader>
                <StyledP>
                  I was responsible for all of the software to extract and
                  process the raw scanner data. The current NFC scanners are not
                  connected to the backend or any local networks. This means
                  that data must be extracted manually via a serial port. Once
                  extracted, this data is matched with the visitor's actual
                  information. Lastly, all processed data is partitioned per
                  visitor and per exhibitor so each can access their leads and
                  contacts individually. <br />
                  <br />
                  Amongst the features in the scripts are:
                </StyledP>
                <StyledUL>
                  <li>
                    Continuous scanning of the serial port enables scanners to
                    be quickly offloaded.
                  </li>
                  <li>
                    Fully independent of the backend, so it remains functional
                    in remote locations.
                  </li>
                  <li>Settings to process select time-frames.</li>
                  <li>
                    Partitioning of data by event schedules, so an organizer
                    knows who attended specific sessions hosted in the same
                    location.
                  </li>
                  <li>
                    ID designation tool. Each scanner has its unique device
                    address but is given a human friendly integer ID to be
                    tracked. This tool enables the automatic assignment and
                    updating of IDs when a scanner is put out of service,
                    repaired or transferred. Ensures each ID is unique across
                    all devices.
                  </li>
                </StyledUL>
                <StyledContentHeader as="h4">Highlights</StyledContentHeader>
                <StyledUL>
                  <li>
                    The largest deployment was with 150 devices across two
                    venues, simultaneously over the span of two days with
                    thousands of visitors.
                  </li>
                </StyledUL>
              </StyledColumn>
            </Grid.Row>
          </Grid>
          <Divider />
          <Visibility once={false}>
            {this.state.bottom_button ? <StyledBackButton /> : <div></div>}
          </Visibility>
        </StyledContainer>
      </Transition>
    );
  }
}

export default SODataExtraction;
