import React, { Component } from "react";
import portal_screenshot from "images/portal_screenshot_mini.png";
import portal_mobile from "images/SOWAiphone.png";
import {
  Divider,
  Image,
  Grid,
  Transition,
  Visibility,
} from "semantic-ui-react";
import {
  StyledP,
  StyledUL,
  StyledColumn,
  StyledHeaderMain,
  StyledSubHeader,
  StyledBackButton,
  StyledContentHeader,
  StyledContainer,
} from "./StyledComponents";

import { Desktop, MaxTablet } from "utilities";

class SOWebApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom_button: false,
      visible: false,
    };
    this.showBottomButton = this.showBottomButton.bind(this);
    this.hideBottomButton = this.hideBottomButton.bind(this);
  }

  componentWillMount() {
    this.setState(() => ({ bottom_button: false }));
  }

  componentDidMount() {
    this.setState(() => ({ visible: true }));
    window.scrollTo(0, 0);
  }

  showBottomButton() {
    // console.log('showing BB')
    this.setState(() => ({ bottom_button: true }));
  }

  hideBottomButton() {
    // console.log('hiding BB')
    this.setState(() => ({ bottom_button: false }));
  }

  render() {
    const is_visible = this.state.visible;
    return (
      <Transition
        visible={is_visible}
        animation="fade"
        duration={{ hide: 0, show: 500 }}
        style={{ padding: "0px 0px", margin: "0px 0px" }}
      >
        <StyledContainer id="SOWebAppStyledContainer">
          <Desktop>
            <StyledHeaderMain>Shake-on Web Application</StyledHeaderMain>
            <StyledSubHeader>For: Shake-on B.V.</StyledSubHeader>

            <Visibility
              onOnScreen={this.hideBottomButton}
              onOffScreen={this.showBottomButton}
              once={false}
            >
              {this.state.bottom_button ? <div></div> : <StyledBackButton />}
            </Visibility>

            <Divider />
            <Grid
              // columns={3}
              divided
              stackable
              columns="equal"
            >
              <Grid.Row>
                <StyledColumn>
                  <StyledContentHeader as="h4">What is it?</StyledContentHeader>
                  <StyledP>
                    This web application is part of the Shake-on eco-system and
                    enables users to see whom they met using a smart bracelet.
                    The primary goal is for users to quickly overview whom they
                    met and where.
                    <br />
                    <br />
                    Each initial contact only shows limited information and
                    users may send connection requests to further exchange
                    details such as their e-mails and phone numbers. Users may
                    also integrate their LinkedIn accounts to automatically
                    complete their profiles and enable others to easily view
                    their LinkedIn page.
                  </StyledP>
                  <StyledContentHeader as="h4">
                    Relevant Technology
                  </StyledContentHeader>
                  <StyledUL>
                    <li> Node.JS, React.JS, Redux.JS </li>
                    <li> Axios, Thunk </li>
                    <li> HTML, CSS, SASS </li>
                  </StyledUL>
                </StyledColumn>
                <StyledColumn width={4}>
                  <Image
                    rounded
                    src={portal_mobile}
                    centered
                    style={{ maxHeight: "470px" }}
                  />
                </StyledColumn>
                <StyledColumn>
                  <StyledContentHeader as="h4">
                    What was my role?
                  </StyledContentHeader>
                  <StyledP>
                    The website was first developed by a colleague who left
                    shortly after its initial completion. From then on I have
                    inherited his work and implemented a redesign, as well as
                    ongoing maintenance and improvements.
                    <br />
                    <br />
                    As a major change I rehauled the Redux Store using a new API
                    and data structure. The new data structure necitated
                    reworking most of the existing components. Additionally, I
                    created a grid approach, added responsive elements and added
                    user features such as automatic login links.
                  </StyledP>
                  <StyledContentHeader as="h4">Highlights</StyledContentHeader>
                  {/* <StyledP> */}
                  <ul>
                    <li>
                      {" "}
                      Responsive networking application supporting mobile,
                      tablet and desktop.{" "}
                    </li>
                    <li> User sessions and LinkedIn integration </li>
                  </ul>
                  {/* </StyledP> */}
                </StyledColumn>
              </Grid.Row>
            </Grid>
            <Divider />
            <Image rounded bordered src={portal_screenshot} />
            <Divider />
            {this.state.bottom_button ? <StyledBackButton /> : null}
          </Desktop>

          <MaxTablet>
            <StyledHeaderMain>Shake-on Web Application</StyledHeaderMain>
            <StyledSubHeader>For: Shake-on B.V.</StyledSubHeader>
            {/* <StyledSubHeader>
              Live: <a href="https://app.shake-on.com/">app.shake-on.com</a>{" "}
            </StyledSubHeader> */}
            {/* <StyledSubHeader>
              Source code: <span style={{ color: "gray" }}>private</span>{" "}
            </StyledSubHeader> */}

            <Visibility
              onOnScreen={this.hideBottomButton}
              onOffScreen={this.showBottomButton}
              once={false}
            >
              {this.state.bottom_button ? <div></div> : <StyledBackButton />}
            </Visibility>

            <Divider />
            <Grid
              // columns={3}
              divided
              stackable
              columns="equal"
            >
              <Grid.Row>
                <StyledColumn>
                  <StyledContentHeader as="h4">What is it?</StyledContentHeader>
                  <StyledP>
                    This web application is part of the Shake-on eco-system and
                    enables users to see whom they met using a smart bracelet.
                    The primary goal is for users to quickly overview whom they
                    met and where.
                    <br />
                    <br />
                    Each initial contact only shows limited information and
                    users may send connection requests to further exchange
                    details such as their e-mails and phone numbers. Users may
                    also integrate their LinkedIn accounts to automatically
                    complete their profiles and enable others to easily view
                    their LinkedIn page.
                  </StyledP>
                  <Image rounded bordered src={portal_screenshot} />
                  <StyledContentHeader as="h4">
                    What was my role?
                  </StyledContentHeader>
                  <StyledP>
                    The website was first developed by a colleague who left
                    shortly after its initial completion. From then on I have
                    inherited his work and implemented a redesign, as well as
                    ongoing maintenance and improvements.
                    <br />
                    <br />
                    As a major change I rehauled the Redux Store using a new API
                    and data structure. The new data structure necitated
                    reworking most of the existing components. Additionally, I
                    created a grid approach, added responsive elements and added
                    user features such as automatic login links.
                  </StyledP>

                  {/* </StyledColumn> */}
                  {/* <StyledColumn > */}
                  <Divider />
                  <StyledContentHeader as="h4">
                    Relevant Technology
                  </StyledContentHeader>
                  <StyledUL>
                    <li> Node.JS, React.JS, Redux.JS </li>
                    <li> Axios, Thunk </li>
                    <li> HTML, CSS, SASS </li>
                  </StyledUL>
                  <Divider />
                  <StyledContentHeader as="h4">Highlights</StyledContentHeader>
                  <StyledP>
                    <ul>
                      <li>
                        {" "}
                        Responsive networking application supporting mobile,
                        tablet and desktop.{" "}
                      </li>
                      <li> User sessions and LinkedIn integration </li>
                    </ul>
                  </StyledP>

                  <Image
                    rounded
                    src={portal_mobile}
                    centered
                    style={{ marginTop: "3em", maxHeight: "470px" }}
                  />
                </StyledColumn>
              </Grid.Row>
            </Grid>
            <Divider />
            <Visibility once={false}>
              {this.state.bottom_button ? <StyledBackButton /> : <div></div>}
            </Visibility>
          </MaxTablet>
        </StyledContainer>
      </Transition>
    );
  }
}

export default SOWebApp;
