import React from "react";
import { useMediaQuery } from "react-responsive";

export const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};
export const Tablet = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  return isTablet ? children : null;
};
export const MaxTablet = ({ children }) => {
  const isTablet = useMediaQuery({ maxWidth: 991 });
  return isTablet ? children : null;
};
export const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};

export const CustomResponsive = ({
  maxWidth = 3840,
  minWidth = 0,
  children,
}) => {
  const isFit = useMediaQuery({ minWidth: minWidth, maxWidth: maxWidth });
  return isFit ? children : null;
};
