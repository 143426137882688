import React, { Fragment } from "react";
import styled from "styled-components";
import { Container, Header, Grid, Icon, Divider } from "semantic-ui-react";
import { useHistory } from "react-router-dom";

import { CustomResponsive } from "utilities";

export const StyledP = styled.p`
  &&& {
    text-align: justify;
    font-size: 1.1em;
    // color: red;
  }
`;

export const StyledUL = styled.ul`
  &&& {
    font-size: 1.1em;
    line-height: 1.5em;
  }
`;

export const StyledOL = styled.ol`
  &&& {
    font-size: 1.1em;
    line-height: 1.5em;
  }
`;

export const StyledContainer = styled(Container)`
   {
    margin-top: 10em;
    margin-bottom: 4em;
  }
`;

export const StyledColumn = styled(Grid.Column)`
  &&& {
    padding-left: 2em !important;
    padding-right: 2em !important;
  }
  // align-items: center;
`;

export const StyledHeaderMain = styled(Header)`
  &&& {
    // margin-top: 1em !important;
    font-family: "Dosis";
    text-align: center;
    font-size: 3em;
    font-weight: 500;
    margin-bottom: 0.2em;
    @media only screen and (max-width: 600px) {
      margin-bottom: 1em !important;
    }
    // @media only screen and (max-width: 991px) {
    //     color:  rgba(242,242,255,0.65);
    // }
  }
`;

export const StyledSubHeader = styled(Header)`
  &&& {
    text-align: center;
    font-size: 1.2em;
    margin: 0.2em auto;
    // @media only screen and (max-width: 991px) {
    //     color:  rgba(242,242,255,0.65);
    // }
  }
`;

export const StyledContentHeader = styled(Header)`
  &&& {
    font-family: "Dosis";
    font-size: 2.3em;
    font-weight: 500;
    // @media only screen and (max-width: 991px) {
    //     color:  rgba(242,242,255,0.65);
    // }
  }
`;

export const StyledCaption = styled.p`
  &&& {
    font-size: 1em;
    max-width: 95%;
    color: #6d6d6d;
    // padding: 0em 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
`;

export const StyledBackLink = styled.span`
  &&& {
    // height: 2em;
    line-height: 1.5em;
    // margin-top: 1em !important;
    display: inline-flex;
    align-items: center;
    :hover {
      color: #7c7c7c;
      cursor: pointer;
    }
  }
`;

export const StyledBackText = styled(Header.Content)`
  &&& {
    font-size: 1.5em;
    line-height: 1.5em;
  }
`;

const StyledIcon = styled(Icon)`
  &&& {
    height: 1.5em;
    line-height: 1.6em;
    font-size: 1.5em;
  }
`;

export const StyledBackButton = () => {
  const history = useHistory();
  return (
    <Fragment>
      <CustomResponsive maxWidth={480}>
        <div>
          <Divider />
          <StyledBackLink onClick={() => history.goBack()}>
            <StyledIcon name="arrow alternate circle left" />
            <StyledBackText>Back</StyledBackText>
          </StyledBackLink>
        </div>
      </CustomResponsive>
      <CustomResponsive minWidth={481}>
        <StyledBackLink onClick={() => history.goBack()}>
          <StyledIcon name="arrow alternate circle left" />
          <StyledBackText>Back</StyledBackText>
        </StyledBackLink>
      </CustomResponsive>
    </Fragment>
  );
};
