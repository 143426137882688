import React, { Component } from "react";
import roller_image from "images/heeremaroll.PNG";
import damaged_image from "images/heeremafaailure.PNG";
import {
  Divider,
  Image,
  Grid,
  Transition,
  Visibility,
} from "semantic-ui-react";
import {
  StyledP,
  StyledUL,
  StyledBackButton,
  StyledCaption,
  StyledColumn,
  StyledHeaderMain,
  StyledSubHeader,
  StyledContentHeader,
  StyledContainer,
} from "./StyledComponents";

class Heerema extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom_button: false,
      visible: false,
    };
    this.showBottomButton = this.showBottomButton.bind(this);
    this.hideBottomButton = this.hideBottomButton.bind(this);
  }

  componentWillMount() {
    this.setState(() => ({ bottom_button: false }));
  }

  componentDidMount() {
    this.setState(() => ({ visible: true }));
    window.scrollTo(0, 0);
  }

  showBottomButton() {
    this.setState(() => ({ bottom_button: true }));
  }

  hideBottomButton() {
    this.setState(() => ({ bottom_button: false }));
  }

  render() {
    const is_visible = this.state.visible;
    return (
      <Transition
        visible={is_visible}
        animation="fade"
        duration={{ hide: 0, show: 500 }}
        style={{ padding: "0px 0px", margin: "0px 0px" }}
      >
        <StyledContainer id="Heerema">
          <StyledHeaderMain>
            Full Scale Testing of 100mm Coated Pipeline Reeling
          </StyledHeaderMain>
          <StyledSubHeader>
            For:{" "}
            <a
              href="https://hmc.heerema.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Heerema Marine Contractors
            </a>
          </StyledSubHeader>
          <StyledSubHeader>
            Conference Paper:&nbsp;
            <a
              href="https://www.researchgate.net/publication/277555532_Extension_of_the_reel-ability_envelope_of_the_Aegir_Synergy_between_analysis_and_full-scale_testing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Researchgate
            </a>
          </StyledSubHeader>

          <Visibility
            onOnScreen={this.hideBottomButton}
            onOffScreen={this.showBottomButton}
            once={false}
          >
            {this.state.bottom_button ? <div></div> : <StyledBackButton />}
          </Visibility>

          <Divider />
          <Grid columns={2} divided stackable>
            <Grid.Row>
              <StyledColumn>
                <StyledContentHeader as="h4">The Project</StyledContentHeader>
                <StyledP>
                  The Innovation Department of Heerema Marine Contractors was
                  tasked with exploring new technologies and techniques for
                  application in the company's offerings. The project I worked
                  in was a feasability study for the use of a thick insulation
                  coating in sub-sea pipelines. Specifically, if these large
                  diameter pipes could be reeled without compromising the
                  insulation layer.
                  <br />
                  <br />
                  <Image size="big" rounded centered src={damaged_image} />
                  <StyledCaption>
                    The cross sections of two field joints after testing.
                  </StyledCaption>
                  The project culminated in full-scale testing of pipeline
                  reeling at a facility in Harriott Watt University, Edinburgh.
                  This consisted of an injection site, where IMPP joints were
                  formed, a refrigeration unit, a bending rig with various
                  curvatures, and a roller. Various pipe and pipe joint
                  configurations were passed through these stages to fully
                  simulate the assembly, reeling and rolling stages employed at
                  sea. At the end of each test, the pipes would be inspected and
                  cut open to reveal any damage to the insulation layers. The
                  ultimate goal was to determine if such piping could be reeled
                  and used on Heerema vessels.
                </StyledP>
                <Image rounded size="big" centered src={roller_image} />
                <StyledCaption>
                  A jointed pipe section is subjected to a winder roller with a
                  12.5 mT load.
                </StyledCaption>
              </StyledColumn>
              <StyledColumn>
                <StyledContentHeader as="h4">
                  What was my role?
                </StyledContentHeader>
                <StyledP>
                  As a working student I was privileged with tasks considerably
                  more difficult than coffee runs:
                </StyledP>
                <StyledUL>
                  <li>
                    Planning the field test timeline. I was provided with the
                    required test congifurations that had to be tested and it
                    was my responsiblity to schedule them. The test period
                    lasted 6 weeks and included parties from the Netherlands,
                    United Kingdom and Norway. Planning was done in Microsoft
                    Project and had to account for several factors:
                    <ul>
                      <li>Dependencies on the findings of previous tests</li>
                      <li>The availability of equipment such as forklifts</li>
                      <li>
                        Transition periods to reconfigure equipment and
                        facilities
                      </li>
                      <li>Contractors present on site</li>
                      <li>Delays or failures in specific tests</li>
                      <li>Availability of Heerema personnel</li>
                    </ul>
                  </li>
                  <li>
                    Drafting the requirements for the IMPP quality control
                  </li>
                  <li>
                    Helping manage the field tests. Performed various tasks
                    on-site including setup, checks and daily documentation
                    covering progress and findings.
                  </li>
                  <li>
                    Collecting and reporting data during field tests. This
                    included documenting the timing for each stage, temperatures
                    of various equipment, structural measurments etc.
                  </li>
                  <li>
                    Reporting on each pipe and field joint. After the field
                    testing I was responsible for a comprehensive report on each
                    individual test. This included documenting the process for
                    each joint, and noting all deformations. I examined every
                    single cut piece in the field and recorded all delaminations
                    and voids in the injections.
                  </li>
                </StyledUL>
                <StyledContentHeader as="h4">Highlights</StyledContentHeader>
                <StyledUL>
                  <li>
                    The field tests covered nearly two months during which I was
                    stationed in Scotland. Each day was a 12 hour shift in an
                    industrial environment, a fun change from lecture halls and
                    offices.
                  </li>
                  <li>
                    Marine engineering was a big shift from my academic
                    background, but the managment skills I picked up at Heerema
                    have been universally applicable ever since!
                  </li>
                </StyledUL>
              </StyledColumn>
            </Grid.Row>
          </Grid>
          <Divider />
          <Visibility once={false}>
            {this.state.bottom_button ? <StyledBackButton /> : <div></div>}
          </Visibility>
        </StyledContainer>
      </Transition>
    );
  }
}

export default Heerema;
