import React, { Component } from "react";
import NN_image from "images/INGshake_cropped_mini.jpg";
import handshake_image from "images/SO bracelet.jpg";
import {
  Divider,
  Image,
  Grid,
  Transition,
  Visibility,
} from "semantic-ui-react";
import {
  StyledP,
  StyledUL,
  StyledColumn,
  StyledHeaderMain,
  StyledSubHeader,
  StyledBackButton,
  StyledContentHeader,
  StyledCaption,
  StyledContainer,
} from "./StyledComponents";

class SOHandshake extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom_button: true,
      visible: false,
    };
    this.showBottomButton = this.showBottomButton.bind(this);
    this.hideBottomButton = this.hideBottomButton.bind(this);
  }

  componentWillMount() {
    this.setState(() => ({ bottom_button: false }));
  }

  componentDidMount() {
    this.setState(() => ({ visible: true }));
    window.scrollTo(0, 0);
  }

  showBottomButton() {
    // console.log('showing BB')
    this.setState(() => ({ bottom_button: true }));
  }

  hideBottomButton() {
    // console.log('hiding BB')
    this.setState(() => ({ bottom_button: false }));
  }

  render() {
    const is_visible = this.state.visible;
    return (
      <Transition
        visible={is_visible}
        animation="fade"
        duration={{ hide: 0, show: 500 }}
        style={{ padding: "0px 0px", margin: "0px 0px" }}
      >
        <StyledContainer id="SOHandshake">
          <StyledHeaderMain>
            Gesture Recognition Algorithm for a Smart Bracelet
          </StyledHeaderMain>
          <StyledSubHeader>For: Shake-on B.V.</StyledSubHeader>

          <Visibility
            onOnScreen={this.hideBottomButton}
            onOffScreen={this.showBottomButton}
            once={false}
          >
            {this.state.bottom_button ? <div></div> : <StyledBackButton />}
          </Visibility>

          <Divider />
          <Grid columns={2} divided stackable>
            <Grid.Row>
              <StyledColumn>
                <StyledContentHeader as="h4">What is it?</StyledContentHeader>
                <StyledP>
                  {/* <Image floated='right' src={handshake_image} style={{ marginLeft: '2em', marginBottom: '2%', maxWidth: '50%' }}/> */}
                  Shake-on B.V. develops smart bracelets that enable users to
                  digitally connect just by shaking hands. This enables users to
                  network faster and not worry about collecting business cards
                  or recording contact details.
                </StyledP>
                <StyledP>
                  What makes this product unique is that it seemlesly integrates
                  technology into how users meet: no button pressing, app or
                  additional effort is required. In order to achieve this, the
                  bracelet uses an algorithm to differentiate a handshake from
                  other movements and then matches it to its corresponding
                  handshake.
                </StyledP>
                <StyledContentHeader as="h4">
                  Relevant Technology
                </StyledContentHeader>
                <StyledUL>
                  <li> Matlab, C </li>
                  <li> Machine Learning, Artificial Neural Networks </li>
                  <li> Keil, Nordic NRF52 </li>
                </StyledUL>
                <Image size="big" centered rounded src={handshake_image} />
                <StyledCaption>
                  Upon shaking hands visitors digitially exchange their contact
                  details
                </StyledCaption>
              </StyledColumn>
              <StyledColumn>
                <Image centered size="big" rounded src={NN_image} />
                <StyledContentHeader as="h4">
                  What was my role?
                </StyledContentHeader>
                <StyledP>
                  I have developed the handshake algorithm from the ground up
                  using Machine Learning techniques. This includes:
                </StyledP>
                <StyledUL>
                  <li>Selecting classifier model candidates</li>
                  <li>Designing the test setup to collect training data</li>
                  <li>Collecting the training data using volunteers</li>
                  <li>
                    Post processing collected data including feature extraction
                  </li>
                  <li>Ineractive GUI for signal trimming</li>
                  <li>Training and tuning the ML algorithms</li>
                  <li>
                    Porting the trained models for use in embedded software
                  </li>
                </StyledUL>
                <StyledContentHeader as="h4">Highlights</StyledContentHeader>
                <StyledP>
                  <ul>
                    <li>Works with soft, slow, loose shakes etc.</li>
                    <li>False positive rate less than 1&#37;</li>
                    <li>Runs in real time on an ARM Cortex-M4</li>
                    <li>Optimized for energy efficiency</li>
                  </ul>
                </StyledP>
              </StyledColumn>
            </Grid.Row>
          </Grid>
          <Divider />
          <Visibility once={false}>
            {this.state.bottom_button ? <StyledBackButton /> : <div></div>}
          </Visibility>
        </StyledContainer>
      </Transition>
    );
  }
}

export default SOHandshake;
