import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const RocketElementToggle = () => {
  const location = useLocation();

  useEffect(() => {
    let timeout;
    let cleanup = () => null;
    if (location.pathname === "/") {
      document.getElementById("renderer-wrapper").className = "";
      document.getElementById("renderer-wrapper").style.display = "block";
    } else {
      document.getElementById("renderer-wrapper").className = "fade";
      timeout = setTimeout(
        () =>
          (document.getElementById("renderer-wrapper").style.display = "none"),
        1000
      );
      cleanup = () => clearTimeout(timeout);
    }
    return cleanup;
  }, [location]);

  return null;
};

export default RocketElementToggle;
