import React, { useState, Fragment } from "react";
import { Image, Card, Label, Transition } from "semantic-ui-react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const StyledCard = styled(Card)`
  &&& {
    max-width: 23em;
    height: 100%;
    width: 100%;
    margin: 0 0;
    :hover {
      box-shadow: 0 1px 30px 1px #000, 0 0 0 1px #000;
      cursor: pointer;
    }
  }
`;

const GridCard = ({ to, imageSrc, Description, Name, tags, Year }) => {
  let history = useHistory();
  const [showPreview, setShowPreview] = useState(false);

  const default_img = imageSrc;
  const is_visible = true;

  const handleClick = () => {
    history.push(to);
  };

  return (
    <Fragment>
      {/* <Transition
      visible={is_visible}
      animation="fade"
      duration={{ hide: 0, show: 500 }}
      style={{ padding: "0px 0px", margin: "0px 0px", flexGrow: "1" }}
    > */}
      <StyledCard
        color="grey"
        centered={true}
        onMouseLeave={() => setShowPreview(false)}
        onMouseOver={() => setShowPreview(true)}
        // link
        // href={to}
        onClick={handleClick}
        // as={Link}
        // to={this.props.to}
      >
        <Transition visible={showPreview} animation="fade" duration={400}>
          <Card.Content
            style={{
              position: "absolute",
              top: "0px",
              height: "220px",
              width: "100%",
              padding: "0 0",
              margin: "0 0",
            }}
            id="taco"
          >
            <Image
              src={default_img}
              style={{
                display: "block",
                height: "220px",
                objectFit: "cover",
                margin: "0 auto",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "0",
                height: "220px",
                width: "100%",
                opacity: "0.9",
                backgroundColor: "rgb(1, 255, 232)",
              }}
            >
              {" "}
            </div>
            <div
              style={{
                textAlign: "center",
                margin: "0px 30px",
                alignItems: "center",
                fontFamily: "Dosis",
                fontWeight: "500",
                fontSize: "1.6em",
                position: "absolute",
                lineHeight: "35px",
                top: "50%",
                transform: "translateY(-50%)",
                color: "#000",
              }}
            >
              {Description}
            </div>
          </Card.Content>
        </Transition>
        <Transition visible={!showPreview} animation="fade" duration={400}>
          <Image
            src={default_img}
            style={{
              display: "block",
              height: "220px",
              objectFit: "cover",
              margin: "0 auto",
            }}
          />
        </Transition>
        <Card.Content style={{ minHeight: "91px" }}>
          <Card.Header>{Name}</Card.Header>
          <Card.Meta>
            <span className="date">{Year}</span>
          </Card.Meta>
        </Card.Content>
        <Card.Content extra textAlign="center">
          {tags &&
            tags.map((label) => {
              return (
                <Label style={{ margin: "3px 3px" }} key={label}>
                  {label}
                </Label>
              );
            })}
        </Card.Content>
      </StyledCard>
      {/* </Transition> */}
    </Fragment>
  );
};

export default GridCard;
