import React from "react";
import { Grid, Header } from "semantic-ui-react";
import GridCard from "./GridCard";
import iot_preview from "images/iotpreview.png";
import portal_screenshot from "images/portal_screenshot_mini.png";
import handshake_image from "images/handshake_mini.png";
import oilngas_image_cropped from "images/oilngas_cropped.png";
import spine_front_image from "images/spienfront_mini.PNG";
import checkin_trimmed_image from "images/checkin_cropped.jpg";
import code_image from "images/code3.jpeg";
import python_image from "images/pythoncode.jpg";
import race_image from "images/vroom_mini.png";
import styled from "styled-components";

const StyledGrid = styled(Grid)`
  &&& {
    display: flex !important;
    margin-left: 50px !important;
    margin-right: 50px !important;
    padding: 0 auto;
    @media only screen and (max-width: 600px) {
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }
`;

const StyledColumn = styled(StyledGrid.Column)`
  &&& {
    align-items: center;
  }
`;

const CardGrid = (props) => {
  return (
    <div>
      {/* <Transition
        visible={true}
        animation={"fade"}
        duration={{ hide: 0, show: 500 }}
      > */}
      <Header
        style={{
          marginTop: "0em",
          marginBottom: "1em",
          fontFamily: "Dosis",
          textAlign: "center",
          fontSize: "50px",
          fontWeight: "500",
        }}
      >
        My Projects
      </Header>
      {/* </Transition> */}
      <StyledGrid id="CardGrid" centered>
        <StyledColumn stretched mobile={16} tablet={8} computer={4}>
          <GridCard
            to="/projects/xlsportal"
            imageSrc={iot_preview}
            Name="Xeelas IoT Portal"
            Year="2018 - Xeelas"
            tags={[
              "React.JS",
              "Redux.JS",
              "React-Testing-Library",
              "React Hooks + Context",
            ]}
            Description="A web application to install, configure and manage an IoT fleet"
            id="XlsPortal"
          />
        </StyledColumn>
        <StyledColumn stretched mobile={16} tablet={8} computer={4}>
          <GridCard
            to="/projects/sowebapp"
            imageSrc={portal_screenshot}
            imageHoverSrc={handshake_image}
            Name="Shake-on Web App"
            Year="2018 - Shake-on"
            tags={[
              "React.JS",
              "Redux.JS",
              "HTML/CSS",
              // "Thunk",
              // "Axios",
              "AWS",
            ]}
            Description="A web application to view and download contacts"
            id="SOWebApp"
          />
        </StyledColumn>
        <StyledColumn stretched mobile={16} tablet={8} computer={4}>
          <GridCard
            to="/projects/sohandshake"
            imageSrc={handshake_image}
            Name="Handshake Recognition Algorithm"
            Year="2018 - Shake-on"
            tags={[
              "Machine Learning",
              "Data Analysis",
              "Neural Networks",
              "Signal Processing",
            ]}
            Description="An artificial neural network for smart wearables"
            id="SOHandshake"
          />
        </StyledColumn>
        <StyledColumn stretched mobile={16} tablet={8} computer={4}>
          <GridCard
            to="/projects/tustewart"
            imageSrc={spine_front_image}
            Name="Modelling and Simulating a Compliant Stewart Platform"
            Year="2017 - TU Delft"
            tags={["Robotics", "Control", "Simulation", "SimuLink"]}
            Description="The design and control of a humanoid robotic torso"
            id="TUStewart"
          />
        </StyledColumn>
        <StyledColumn stretched mobile={16} tablet={8} computer={4}>
          <GridCard
            to="/projects/socheckin"
            imageSrc={checkin_trimmed_image}
            Name="Bluetooth Check-in and Registration System"
            Year="2017 - Shake-on"
            tags={[
              "Node.JS",
              "React.JS",
              "Express.JS",
              "Redux.JS",
              "HTML/CSS",
              "BLE",
            ]}
            Description="An industrial level system for Bluetooth devices"
            id="SOCheckin"
          />
        </StyledColumn>
        <StyledColumn stretched mobile={16} tablet={8} computer={4}>
          <GridCard
            to="/projects/sodataextraction"
            imageSrc={python_image}
            Name="Hardware fleet management and data extraction"
            Year="2017 - TU Delft"
            tags={["Python", "NumPy", "Pandas", "Serial"]}
            Description="Automating data extraction and processing"
            id="SODataExtraction"
          />
        </StyledColumn>
        <StyledColumn stretched mobile={16} tablet={8} computer={4}>
          <GridCard
            to="/projects/sonfcscanner"
            imageSrc={code_image}
            Name="NFC Writer and NFC Scanner"
            Year="2016 - Shake-on"
            tags={["Python", "NFC", "Bash / Shell Script", "Ansible"]}
            Description="Linux based NFC devices"
            id="SONFCScanner"
          />
        </StyledColumn>
        <StyledColumn stretched mobile={16} tablet={8} computer={4}>
          <GridCard
            to="/projects/heerema"
            imageSrc={oilngas_image_cropped}
            Name="Full Scale Testing of Subsea Pipeline Reeling "
            Year="2015 - Heerema Marine Contractors"
            tags={["Oil & Gas", "MS Project", "Structural Testing"]}
            Description="Helping plan, manage and report upon full scale testing of subsea pipelines"
            id="Heerema"
          />
        </StyledColumn>
        <StyledColumn stretched mobile={16} tablet={8} computer={4}>
          <GridCard
            to="/projects/dut"
            imageSrc={race_image}
            Name="Design and Production of a 4WD Suspension System"
            Year="2012 - Formula Student Racing - DUT Racing"
            tags={["Automotive", "CAD"]}
            Description="Designing and building a race car!"
            id="DUT"
          />
        </StyledColumn>
      </StyledGrid>
    </div>
  );
};

export default CardGrid;
