import React, { Component } from "react";
import StewartSL_image from "images/fullSmm_contrast.png";
import Combined_wheelchair_image from "images/combined_wheelchair.png";
import dimensions_image from "images/dimensions.png";
import {
  Divider,
  Image,
  Grid,
  Transition,
  Visibility,
  Header,
} from "semantic-ui-react";
import {
  StyledP,
  StyledUL,
  StyledOL,
  StyledCaption,
  StyledColumn,
  StyledHeaderMain,
  StyledSubHeader,
  StyledBackButton,
  StyledContentHeader,
  StyledContainer,
} from "./StyledComponents";

class TUStewart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom_button: false,
      visible: false,
    };
    this.showBottomButton = this.showBottomButton.bind(this);
    this.hideBottomButton = this.hideBottomButton.bind(this);
  }

  componentWillMount() {
    this.setState(() => ({ bottom_button: false }));
  }

  componentDidMount() {
    this.setState(() => ({ visible: true }));
    window.scrollTo(0, 0);
  }

  showBottomButton() {
    console.log("showing BB");
    this.setState(() => ({ bottom_button: true }));
  }

  hideBottomButton() {
    console.log("hiding BB");
    this.setState(() => ({ bottom_button: false }));
  }

  render() {
    const is_visible = this.state.visible;
    return (
      <Transition
        visible={is_visible}
        animation="fade"
        duration={{ hide: 0, show: 500 }}
        style={{ padding: "0px 0px", margin: "0px 0px" }}
      >
        <StyledContainer id="TUStewart">
          <StyledHeaderMain>
            The Design and Control of a Humanoid Robotic Torso
          </StyledHeaderMain>
          <StyledSubHeader>
            For: Delft University of Technology{" "}
          </StyledSubHeader>
          <StyledSubHeader>
            Source code:{" "}
            <a
              href="https://github.com/AdeBode/ComplaintStewartPlatform"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>{" "}
          </StyledSubHeader>
          <StyledSubHeader>
            Model Equations:{" "}
            <a
              href="https://s3-eu-west-1.amazonaws.com/compliant-stewart-platform/justmaths.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              PDF
            </a>
          </StyledSubHeader>

          <Visibility
            onOnScreen={this.hideBottomButton}
            onOffScreen={this.showBottomButton}
            once={false}
          >
            {this.state.bottom_button ? <div></div> : <StyledBackButton />}
          </Visibility>

          <Divider />
          <Grid columns={2} divided stackable>
            <Grid.Row>
              <StyledColumn>
                <StyledContentHeader as="h4">The Project</StyledContentHeader>
                <Image size="large" centered src={Combined_wheelchair_image} />
                <StyledCaption>
                  {" "}
                  Part of the Simulink model I developed. The humanoid CAD model
                  is made with anatomically correct dimensions and segment
                  masses.{" "}
                </StyledCaption>
                <StyledP>
                  The topic of my Masters thesis was to create a robotic
                  representation of the human trunk in paraplegic wheelchair
                  users. This topic came about as an exchange between TU Delft
                  and the Tokyo Institute of Technology where I worked for 6
                  months. The host lab required a wheelchair based topic, and I
                  took the opportunity to add a Robotics twist.
                  <br />
                  <br />
                  The ultimate idea was to enable a robotic mannequin that could
                  be used to develop and test wheelchairs, where it is otherwise
                  difficult to find test subjects. My scope was to research and
                  model a robotic assembly that could be used as the trunk for
                  such a robot. I'm especially proud of the math and
                  programming; you can find the mathematics{" "}
                  <a
                    href="https://s3-eu-west-1.amazonaws.com/compliant-stewart-platform/justmaths.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </StyledP>
                <Image size="large" centered src={StewartSL_image} />
                <StyledCaption>
                  {" "}
                  A 'simplified' Simulink model for a compliant Stewart Platform{" "}
                </StyledCaption>
                <StyledContentHeader as="h4">Technology</StyledContentHeader>
                <StyledUL>
                  <li>Matlab, Simulink, Simmechanics</li>
                  <li>SolidWorks</li>
                </StyledUL>
              </StyledColumn>
              <StyledColumn>
                <StyledContentHeader as="h4">
                  What did I achieve?
                </StyledContentHeader>
                <StyledP>
                  The proposed mechanism was a Stewart platform modified with
                  the addition of visco-elastic elements within each actuating
                  leg. This mimics the mechanical nature of tissue in a
                  paralyzed torso.
                </StyledP>
                <StyledP>
                  The project consisted of three major components:
                </StyledP>
                <StyledOL>
                  <li>
                    A Simulink model used for verification and visualization.
                  </li>
                  <li>
                    A script for generating and solving the equations of motion.
                  </li>
                  <li>
                    A genetic algorithm used to optimize the Stewart platform's
                    parameters.
                  </li>
                </StyledOL>
                <Header as="h4">The Simmechanic model included:</Header>
                <StyledUL>
                  <li>
                    Creating an anatomically proportioned CAD model of a the
                    human body - including correct mass for individual body
                    segments.
                  </li>
                  <li>
                    Integrating the human model with a wheelchair model in
                    Simulink (Simmechanics)
                  </li>
                  <li>
                    Designing a complete Stewart platform in Simmechanics that
                    could be embedded into the humanoid model.
                  </li>
                </StyledUL>
                <Image
                  size="medium"
                  centered
                  src={dimensions_image}
                  style={{ marginTop: "1em" }}
                />
                <StyledCaption>
                  A jointed, anthrapomorphic CAD model with mass segments was
                  not available, hence I created one myself.
                </StyledCaption>
                <Header as="h4">The scripted model consisted of:</Header>
                <StyledUL>
                  <li>
                    A multi bodied system with 13 rigid bodies, 6 massed
                    springs, 12 degrees of freedom and 108 constraints.
                  </li>
                  <li>
                    A script generating the full equations of motion and
                    solution in matrix form, including force and torque loading.
                  </li>
                  <li>6 independent PID controllers, one on each actuator.</li>
                  <li>
                    Full simulation of forward Dynamics, and integration into
                    the Simulink model for validation.
                  </li>
                </StyledUL>
                <Header as="h4">The genetic algorithm:</Header>
                <StyledUL>
                  <li>
                    Optimized 18 design variables including component lengths,
                    stiffness, viscosity, and PID gains.
                  </li>
                  <li>
                    Optimized the assembly to mimic the trajectory and response
                    of a torso based on clinical data.
                  </li>
                </StyledUL>
                <StyledContentHeader as="h4">Highlights</StyledContentHeader>
                <StyledUL>
                  <li>
                    Just generating the equations of motion takes an hour of
                    computing time.
                  </li>
                  <li>
                    Once the script equations are generated, simulating the
                    forward dynamics can be up to 200x faster than Simulink.
                  </li>
                  <li>
                    Simemchanics models tend to drift, which the scripted
                    equations are more resilient to.
                  </li>
                  <li>
                    in the A = Bx system, the B matrix psuedo code would be over
                    1,700 pages when printed out.
                  </li>
                </StyledUL>
              </StyledColumn>
            </Grid.Row>
          </Grid>
          <Divider />
          <Visibility once={false}>
            {this.state.bottom_button ? <StyledBackButton /> : <div></div>}
          </Visibility>
        </StyledContainer>
      </Transition>
    );
  }
}

export default TUStewart;
