import React, { Component } from "react";
import fs1_image from "images/jsfix_mini.png";
import suspension_image from "images/suspension_mini.png";
import {
  Divider,
  Image,
  Grid,
  Transition,
  Visibility,
} from "semantic-ui-react";
import {
  StyledP,
  StyledBackButton,
  StyledCaption,
  StyledColumn,
  StyledHeaderMain,
  StyledSubHeader,
  StyledContentHeader,
  StyledContainer,
} from "./StyledComponents";

class DUT extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom_button: false,
      visible: false,
    };
    this.showBottomButton = this.showBottomButton.bind(this);
    this.hideBottomButton = this.hideBottomButton.bind(this);
  }

  componentWillMount() {
    this.setState(() => ({ bottom_button: false }));
  }

  componentDidMount() {
    this.setState(() => ({ visible: true }));
    window.scrollTo(0, 0);
  }

  showBottomButton() {
    this.setState(() => ({ bottom_button: true }));
  }

  hideBottomButton() {
    this.setState(() => ({ bottom_button: false }));
  }

  render() {
    const is_visible = this.state.visible;
    return (
      <Transition
        visible={is_visible}
        animation="fade"
        duration={{ hide: 0, show: 500 }}
        style={{ padding: "0px 0px", margin: "0px 0px" }}
      >
        <StyledContainer id="DUT">
          <StyledHeaderMain>
            Design and Production of a 4WD Suspension System
          </StyledHeaderMain>
          <StyledSubHeader>
            At:{" "}
            <a
              href="https://www.fsteamdelft.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Formula Student Team Delft
            </a>
          </StyledSubHeader>

          <Visibility
            onOnScreen={this.hideBottomButton}
            onOffScreen={this.showBottomButton}
            once={false}
          >
            {this.state.bottom_button ? <div></div> : <StyledBackButton />}
          </Visibility>

          <Divider />
          <Grid columns={2} divided stackable>
            <Grid.Row>
              <StyledColumn>
                <StyledContentHeader as="h4">The Project</StyledContentHeader>
                <StyledP>
                  Formula Student is a global motorsport competition that pits
                  undergraduates from across the world against each other. Over
                  400 universities around the world compete to engineer a race
                  car across a score of criteria including acceleration,
                  endurance cost and more. Each team has a year to design and
                  build their car, after which they face off at some of the most
                  famous tracks of the world.
                </StyledP>
                <StyledP>
                  DUT12 competed in FS:UK at Silverstone, and in FS:G at
                  Hockenheimring, where the team placed 2nd and 1st place
                  respectively.
                </StyledP>
                <Image size="big" rounded centered src={fs1_image} />
                <StyledCaption>
                  The DUT12 getting ready for action.
                </StyledCaption>
              </StyledColumn>
              <StyledColumn>
                <Image size="big" rounded centered src={suspension_image} />
                <StyledCaption>
                  The front suspension of the DUT12. The shock absorbers are
                  from mountain bikes and connect to the wheel assembly via
                  rockers.{" "}
                </StyledCaption>
                <StyledContentHeader as="h4">
                  What was my role?
                </StyledContentHeader>
                <StyledP>
                  Alongside one fellow engineer, I designed the shock absorber
                  and anti-roll bar suspension of the vehicle. The DUT12 vehicle
                  was the first Delft car to have 4 wheel drive, and thus the
                  suspension changed signifantly over previous generations. The
                  suspension was designed to be adjustable so each driver could
                  tune the suspension to their needs and race conditions. As
                  with all other members of the team, I helped build parts of
                  the car in house. I spent my time on the lathe, creating parts
                  for use in the steering column.
                </StyledP>
              </StyledColumn>
            </Grid.Row>
          </Grid>
          <Divider />
          <Visibility once={false}>
            {this.state.bottom_button ? <StyledBackButton /> : <div></div>}
          </Visibility>
        </StyledContainer>
      </Transition>
    );
  }
}

export default DUT;
