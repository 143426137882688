import React from "react";
import PropTypes from "prop-types";
import { Container, Header, Segment } from "semantic-ui-react";

import "./styles.css";

const HomepageHeading = ({ mobile }) => (
  <Container
    style={{
      position: "absolute",
      zIndex: 1,
      width: "100vw",
      paddingLeft: mobile ? "0" : "15%",
    }}
  >
    <Segment
      floated={mobile ? null : "left"}
      basic
      textAlign={mobile ? "center" : null}
    >
      <Header as="h1" inverted={true} className="nameTitle">
        Anton de Bode
      </Header>
      <Header as="h2" inverted={true} className="nameSubTitle">
        Software Development & Engineering
      </Header>
    </Segment>
  </Container>
);

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
};

export default HomepageHeading;
