import React, { Fragment } from "react";
import MainDesktop from "./components/desktop";
import MainMobile from "./components/mobile";

import { useLocation } from "react-router-dom";

const NavBar = () => {
  let location = useLocation();
  if (location.pathname === "/") {
    return null;
  }
  return (
    <Fragment>
      <MainDesktop />
      <MainMobile />
    </Fragment>
  );
};

export default NavBar;
