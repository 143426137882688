import React, { Component } from "react";
import checkin_image from "images/INGcheckin2.jpg";
import {
  Divider,
  Image,
  Grid,
  Transition,
  Visibility,
} from "semantic-ui-react";
import {
  StyledP,
  StyledUL,
  StyledCaption,
  StyledColumn,
  StyledHeaderMain,
  StyledSubHeader,
  StyledContentHeader,
  StyledBackButton,
  StyledContainer,
} from "./StyledComponents";

class SOCheckin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom_button: false,
      visible: false,
    };
    this.showBottomButton = this.showBottomButton.bind(this);
    this.hideBottomButton = this.hideBottomButton.bind(this);
  }

  componentWillMount() {
    this.setState(() => ({ bottom_button: false }));
  }

  componentDidMount() {
    this.setState(() => ({ visible: true }));
    window.scrollTo(0, 0);
  }

  showBottomButton() {
    console.log("showing BB");
    this.setState(() => ({ bottom_button: true }));
  }

  hideBottomButton() {
    console.log("hiding BB");
    this.setState(() => ({ bottom_button: false }));
  }

  render() {
    const is_visible = this.state.visible;
    return (
      <Transition
        visible={is_visible}
        animation="fade"
        duration={{ hide: 0, show: 500 }}
        style={{ padding: "0px 0px", margin: "0px 0px" }}
      >
        <StyledContainer id="SOCheckin">
          <StyledHeaderMain>
            Bluetooth check-in and Registration System
          </StyledHeaderMain>
          <StyledSubHeader>For: Shake-on B.V.</StyledSubHeader>

          <Visibility
            onOnScreen={this.hideBottomButton}
            onOffScreen={this.showBottomButton}
            once={false}
          >
            {this.state.bottom_button ? <div></div> : <StyledBackButton />}
          </Visibility>

          <Divider />
          <Grid columns={2} divided stackable>
            <Grid.Row>
              <StyledColumn>
                <StyledContentHeader as="h4">What is it?</StyledContentHeader>
                <StyledP>
                  Shake-on B.V. offers on-site check-in and registration
                  services for client events. These services rely on custom
                  software to deliver a fast, intuitive check-in application
                  that ensures visitors are processed quickly. What makes this
                  system truly unique is its use of Bluetooth to assign a smart
                  wearable to the visitor as they check in.
                  <br />
                  <br />
                  The Shake-on check-in system can handle three attendees per
                  minute and can be staffed by third parties within 15 minutes
                  of training. It comes in a variety of configurations depending
                  on the client's needs; online, offline, QR scanning, and
                  displaying event specific information.
                </StyledP>
                <Image
                  rounded
                  size="large"
                  centered
                  src={checkin_image}
                  style={{ marginTop: "1em" }}
                />
                <StyledCaption>
                  A visitor is checked in and recieves a smart bracelet and a
                  printed badge.
                </StyledCaption>
                <StyledContentHeader as="h4">Technology</StyledContentHeader>
                <StyledUL>
                  <li>React.JS</li>
                  <li>Redux.JS</li>
                  <li>Express.JS</li>
                  <li>Node.JS</li>
                  <li>Axios</li>
                  <li>HTML & SASS & CSS Modules</li>
                </StyledUL>
              </StyledColumn>
              <StyledColumn>
                <StyledContentHeader as="h4">
                  What was my role?
                </StyledContentHeader>
                <StyledP>
                  The basis for the application was laid out by a former
                  colleague. Since then, I have created several overhauls to
                  improve performance and add new features.
                  <br />
                  <br />
                  One of the biggest overhauls I performed was rewriting the
                  Bluetooth functionality. Initially the checkin system would
                  risk disconnections, slower transfers and memory leaks. This
                  was excaberated by being in the proximity of several identical
                  machines and potentially hundreds of advertizing Bluetooth
                  devices. By rewriting the code and implementing ES6's
                  Promises, I managed to achieve stable Bluetooth performance.
                  Similarly I reworked the code to quickly check-out the
                  bluetooth devices in parallel; making the check-out process 4
                  times faster than the previous implementation.
                  <br />
                  <br />
                  Other features that I have implemented include:
                </StyledP>
                <StyledUL>
                  <li>
                    Converting the Node script to a Windows Service for
                    continuous service.
                  </li>
                  <li>
                    Handling abrupt disconnections of the Bluetooth dongle from
                    the local computer.
                  </li>
                  <li>Auto-completion during queries, with a debounce.</li>
                  <li>
                    Creating and saving log files, which can then be uploaded to
                    the backend.
                  </li>
                  <li>
                    Rebasing the application to use a new API and data
                    structure.
                  </li>
                </StyledUL>
                <StyledP>
                  I am also responsible for managing the fleet of check-in
                  laptops, ensuring that they are up to date and properly
                  configured for every event.
                </StyledP>
                <StyledContentHeader as="h4">Highlights</StyledContentHeader>
                <StyledUL>
                  <li>
                    A quick check-in and registration system that can scan
                    tickets or query a visitor by name.
                  </li>
                  <li>
                    Available in online and offline configurations depending on
                    the use case.
                  </li>
                  <li>
                    Automatically associates a user with a smart wearable upon
                    check-in via Bluetooth.
                  </li>
                  <li>
                    Bluetooth implementation is guaranteed to work with hundreds
                    of simultaneously advertising Bluetooth devices.
                  </li>
                </StyledUL>
              </StyledColumn>
            </Grid.Row>
          </Grid>
          <Divider />
          <Visibility once={false}>
            {this.state.bottom_button ? <StyledBackButton /> : <div></div>}
          </Visibility>
        </StyledContainer>
      </Transition>
    );
  }
}

export default SOCheckin;
