import React, { Component } from "react";
import { Divider, Grid, Transition, Visibility } from "semantic-ui-react";
import {
  StyledP,
  StyledUL,
  StyledBackButton,
  StyledColumn,
  StyledHeaderMain,
  StyledSubHeader,
  StyledContentHeader,
  StyledContainer,
} from "./StyledComponents";

class SONFCScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottom_button: false,
      visible: false,
    };
    this.showBottomButton = this.showBottomButton.bind(this);
    this.hideBottomButton = this.hideBottomButton.bind(this);
  }

  componentWillMount() {
    this.setState(() => ({ bottom_button: false }));
  }

  componentDidMount() {
    this.setState(() => ({ visible: true }));
    window.scrollTo(0, 0);
  }

  showBottomButton() {
    this.setState(() => ({ bottom_button: true }));
  }

  hideBottomButton() {
    this.setState(() => ({ bottom_button: false }));
  }

  render() {
    const is_visible = this.state.visible;
    return (
      <Transition
        visible={is_visible}
        animation="fade"
        duration={{ hide: 0, show: 500 }}
        style={{ padding: "0px 0px", margin: "0px 0px" }}
      >
        <StyledContainer id="SONFCScanner">
          <StyledHeaderMain>NFC Writer and Scanner</StyledHeaderMain>
          <StyledSubHeader>For: Shake-on B.V.</StyledSubHeader>

          <Visibility
            onOnScreen={this.hideBottomButton}
            onOffScreen={this.showBottomButton}
            once={false}
          >
            {this.state.bottom_button ? <div></div> : <StyledBackButton />}
          </Visibility>

          <Divider />
          <Grid columns={2} divided stackable>
            <Grid.Row>
              <StyledColumn>
                <StyledContentHeader as="h4">What is it?</StyledContentHeader>
                <StyledP>
                  Shake-on B.V. provides mobile NFC scanners intended for the
                  event industry. Prior to implementing custom hardware running
                  on an embedded system, the first version of the scanner was
                  based on a Raspberry Pi equipped with an NFC antenna. These
                  devices scanned NFC tags and stored the scanned data for later
                  extraction. The last version of the Pi scanners could be
                  operated independently of Shake-on, the client simply had to
                  connect the Pi devices via ethernet, and their data would be
                  emailed directly to the client.
                </StyledP>
                <StyledContentHeader as="h4">Technology</StyledContentHeader>
                <StyledUL>
                  <li>Python</li>
                  <li>Bash, Shell</li>
                  <li>Linux</li>
                  <li>Ansible</li>
                </StyledUL>
              </StyledColumn>
              <StyledColumn>
                <StyledContentHeader as="h4">
                  What was my role?
                </StyledContentHeader>
                <StyledP>
                  Along with a fellow developer I was responsible for the
                  development, maintenance and operations of the Pi scanners.
                  The NFC code was written in Python alongside auxillary Shell
                  scripts. With 100+ Pi devices, maintenance and administration
                  was automated via Ansible. This enabled bulk updates, data
                  extraction and maintenance.
                </StyledP>
              </StyledColumn>
            </Grid.Row>
          </Grid>
          <Divider />
          <Visibility once={false}>
            {this.state.bottom_button ? <StyledBackButton /> : <div></div>}
          </Visibility>
        </StyledContainer>
      </Transition>
    );
  }
}

export default SONFCScanner;
