import React, { Component } from "react";
import { Menu, Icon } from "semantic-ui-react";
import scrollToComponent from "react-scroll-to-component";
import { NavLink } from "react-router-dom";

import { Desktop } from "utilities";

class MainDesktop extends Component {
  state = {
    fixed: true,
  };

  componentDidMount() {
    this.setState({ visible: true });
  }

  hideFixedMenu = () => this.setState({ fixed: false });
  showFixedMenu = () => this.setState({ fixed: true });

  scrollToContent() {
    scrollToComponent(this.Blue, {
      offset: 0,
      align: "top",
      duration: 700,
      ease: "inCube",
    });
  }

  render() {
    return (
      <Desktop>
        <Menu
          // fixed={this.state.fixed ? "top" : undefined}
          style={{
            position: "fixed",
            width: "100%",
            height: "2em",
            top: 0,
            zIndex: 1,
            margin: 0,
            borderRadius: 0,
            transform: "translate3d(0,0,0)",
          }}
          inverted={true} //{!fixed}
          size="large"
        >
          <div
            style={{
              display: "flex",
              marginLeft: "50px",
              fontSize: "1.1em",
            }}
          >
            <Menu.Item as={NavLink} exact to="/">
              <Icon name="rocket" />
            </Menu.Item>
            <Menu.Item as={NavLink} to="/home" name="home" />
            <Menu.Item as={NavLink} to="/projects" name="projects" />
            <Menu.Item as={NavLink} to="/cv" name="CV" />
            <Menu.Item as={NavLink} to="/contact" name="contact" />
          </div>
        </Menu>
      </Desktop>
    );
  }
}

export default MainDesktop;
