import React from "react";
import { Container } from "semantic-ui-react";
import styled from "styled-components";

import "./styles.css";
// import { Switch, Route } from "react-router-dom";
// import GridCard from './components/GridCard';

import CardGrid from "./components/CardGrid";

const MainDiv = styled(Container)`
  &&& {
    margin: 0 auto;
    width: 100%;
    padding-top: 1em;
    padding-bottom: 2em;
    @media only screen and (min-width: 893px) {
      padding-top: 6em;
      padding-bottom: 6em;
    }
    @media only screen and (max-width: 892px) {
      padding-top: 10vh;
      margin: 0 0 !important;
    }
  }
`;

const StyledRenderSwitch = styled(Container)`
  &&& {
    max-width: 1500px;
    @media only screen and (min-width: 1200px) {
      width: 1500px;
      // margin-left: auto !important;
      // margin-right: auto !important;
      margin-left: auto !important;
      margin-right: auto !important;
    }
    @media only screen and (min-width: 992px) and (max-width: 1199px) {
      width: 1060px;
      margin-left: auto !important;
      margin-right: auto !important;
    }
    @media only screen and (max-width: 760px) {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }
`;

const Projects = ({ homepage }) => {
  // const [visible, setVisible] = useState(true);
  // const [selectedProject, setSelectedProject] = useState(null);

  if (homepage) {
    return (
      <MainDiv style={{ backgroundColor: "rgb(249,249,250)" }}>
        <Container id="ProjectsContainer" style={{ width: "1300px" }}>
          <StyledRenderSwitch>
            <CardGrid />
          </StyledRenderSwitch>
        </Container>
      </MainDiv>
    );
  } else {
    return (
      <MainDiv>
        <Container id="ProjectsContainer" style={{ width: "1300px" }}>
          <StyledRenderSwitch>
            <CardGrid />
          </StyledRenderSwitch>
        </Container>
      </MainDiv>
    );
  }
};

export default Projects;
