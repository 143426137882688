import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AnimatedSwitch, spring } from "react-router-transition";

import "semantic-ui-css/semantic.min.css";

import "./App.css";

import IntoScreen from "./introScreen";
import RocketElementToggle from "./introScreen/RocketElementToggle";
import Main from "pages/Main";
import NavBar from "pages/Navbar";
import CV from "pages/CV";
import Contact from "pages/Contact";

import Projects from "pages/Projects";
import XeelasPortal from "pages/Projects/components/XeelasPortal";
import SOWebApp from "pages/Projects/components/SOWebApp";
import SOHandshake from "pages/Projects/components/SOHandshake";
import TUStewart from "pages/Projects/components/TUStewart";
import SOCheckin from "pages/Projects/components/SOCheckin";
import SODataExtraction from "pages/Projects/components/SODataExtraction";
import SONFCScanner from "pages/Projects/components/SONFCScanner";
import Heerema from "pages/Projects/components/Heerema";
import DUT from "pages/Projects/components/DUT";

// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
    // transitionDuration: "3s",
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    // damping: 22,
    damping: 52,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

function App() {
  return (
    <Router>
      <RocketElementToggle />
      <NavBar />
      <AnimatedSwitch
        atEnter={bounceTransition.atEnter}
        // atLeave={bounceTransition.atLeave}
        atActive={bounceTransition.atActive}
        className="switch-wrapper"
        mapStyles={mapStyles}
      >
        <Route exact path="/">
          <IntoScreen />
        </Route>
        <Route exact path="/home">
          <Main />
        </Route>
        <Route exact path="/cv">
          <CV />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/projects">
          <Projects />
        </Route>
        <Route exact path="/projects/xlsportal">
          <XeelasPortal />
        </Route>
        <Route exact path="/projects/sowebapp">
          <SOWebApp />
        </Route>
        <Route exact path="/projects/sohandshake">
          <SOHandshake />
        </Route>
        <Route exact path="/projects/tustewart">
          <TUStewart />
        </Route>
        <Route exact path="/projects/socheckin">
          <SOCheckin />
        </Route>
        <Route exact path="/projects/sodataextraction">
          <SODataExtraction />
        </Route>
        <Route exact path="/projects/sonfcscanner">
          <SONFCScanner />
        </Route>
        <Route exact path="/projects/heerema">
          <Heerema />
        </Route>
        <Route exact path="/projects/dut">
          <DUT />
        </Route>
      </AnimatedSwitch>
    </Router>
  );
}

export default App;
