import React, { Component } from "react";
import { Container, Icon, Menu, Segment, Sidebar } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

import "./styles.css";
import { MaxTablet } from "utilities";

class MainMobile extends Component {
  state = {};

  handlePusherClick = () => {
    const { sidebarOpened } = this.state;
    if (sidebarOpened) this.setState({ sidebarOpened: false });
  };

  handleSidebarHide = () => this.setState({ sidebarOpened: false });

  handleToggle = () =>
    this.setState({ sidebarOpened: !this.state.sidebarOpened });

  render() {
    const { sidebarOpened } = this.state;

    return (
      <MaxTablet>
        <Sidebar.Pushable className={"sidebar-fix"}>
          <Sidebar
            as={Menu}
            animation="overlay"
            inverted={true}
            vertical
            visible={sidebarOpened}
            className={"sidebar-window"}
            // style={{ zIndex: "1", height: "100vh" }}
            onHide={this.handleSidebarHide}
          >
            <Menu.Item
              as={NavLink}
              exact
              to="/"
              style={{
                display: "flex",
                height: "3.5em",
              }}
            >
              <Icon name="rocket" circular bordered />
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              exact
              to="/home"
              onClick={this.handleSidebarHide}
            >
              Home
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to="/projects"
              onClick={this.handleSidebarHide}
            >
              Projects
            </Menu.Item>
            <Menu.Item as={NavLink} to="/cv" onClick={this.handleSidebarHide}>
              CV
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to="/contact"
              onClick={this.handleSidebarHide}
            >
              Contact
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher
            dimmed={sidebarOpened}
            onClick={this.handlePusherClick}
            // style={{ margin: "0 0", padding: "0em 0em" }}
          >
            <Segment
              textAlign="center"
              style={{ padding: "0em 0em", borderBottom: "0" }}
              vertical
            >
              <Container>
                <Menu inverted={true} fixed="top" size="large">
                  <Menu.Item
                    inverted={"true"}
                    onClick={this.handleToggle}
                    style={{ border: "none" }}
                  >
                    <Icon name="sidebar" />
                  </Menu.Item>
                </Menu>
              </Container>
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </MaxTablet>
    );
  }
}

export default MainMobile;
