import React, { Fragment, Component } from "react";
import { Segment, Button, Icon, Header, Transition } from "semantic-ui-react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import backgroundImagePlaceholder from "images/sunrise1_blur.jpg";
import backgroundImage from "images/sunrise1.jpg";

const StyledContainer = styled.div`
  &&& {
    height: 100vh;
    position: absolute;
    width: 100%;
    // top: 100%;
    // padding-top: 10vh;
    padding: 30vh 2em;
    @media only screen and (max-width: 767px) {
      padding-top: 10vh;
    }
  }
`;

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidMount() {
    this.setState({ visible: true });
  }

  render() {
    const is_visible = this.state.visible;
    return (
      <Transition
        visible={is_visible}
        animation="fade"
        duration={{ hide: 0, show: 500 }}
      >
        <Fragment>
          <LazyLoadImage
            visibleByDefault
            alt="background-image"
            effect="blur"
            height="100%"
            width="100%"
            style={{
              display: "block",
              width: "100vw",
              height: "100vh",
              objectFit: "cover",
              position: "absolute",
            }}
            placeholderSrc={backgroundImagePlaceholder}
            src={backgroundImage} // use normal <img> attributes as props
            wrapperProps={{ style: { display: "block" } }}
          />
          <StyledContainer>
            <Segment
              raised
              compact
              padded="very"
              textAlign="center"
              style={{ margin: "0em auto" }}
            >
              <Header
                as="h1"
                style={{
                  fontSize: "3em",
                  fontWeight: "400",
                  fontFamily: "Dosis",
                }}
              >
                Contact Me
              </Header>
              <p>If you would like to contact me, please do so via LinkedIn</p>
              <Button
                as="a"
                color="linkedin"
                href="https://www.linkedin.com/in/antondebode/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon name="linkedin" style={{ opacity: "1" }} />
                LinkedIn
              </Button>
            </Segment>
          </StyledContainer>
        </Fragment>
      </Transition>
    );
  }
}

export default Contact;
