import React, { Component } from "react";
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  Transition,
} from "semantic-ui-react";
import styled from "styled-components";
import scrollToComponent from "react-scroll-to-component";

import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Desktop, Tablet, MaxTablet, Mobile } from "utilities";
import Projects from "pages/Projects";
import HomepageHeading from "pages/Navbar/components/constants/HomepageHeading";
import preach_image from "images/sleepygray_crop.jpg";
import backgroundImagePlaceholder from "images/sunrise1.jpg";
import backgroundImage from "images/sunrise1.jpg";

const StyledScrollIcon = styled(Icon)`
  &&& {
    position: absolute;
    padding: 0px 0px;
    bottom: 1em;
    margin: 0px auto;
    left: 0;
    right: 0;
    color: rgb(97, 97, 97);
    :hover {
      color: white;
      cursor: pointer;
    }
  }
`;

const StyledHeader = styled(Header)`
  &&& {
    font-size: 2em;
    color: rgba(242, 242, 255, 0.65);
  }
`;

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      didLoad: false,
    };

    this.scrollToContent = this.scrollToContent.bind(this);
  }

  // componentDidMount() {
  //   window.scrollTo(0, 0);
  // }

  scrollToContent = () => {
    scrollToComponent(this.Blue, {
      align: "top",
      duration: 700,
      ease: "inCube",
    });
  };

  scrollToContentMobile = () => {
    scrollToComponent(this.Blue, {
      offset: 0,
      align: "top",
      duration: 700,
      ease: "inCube",
    });
  };

  render() {
    return (
      <div
        style={{ backgroundColor: "#121217", color: "rgba(255,255,255,0.5)" }}
      >
        <Desktop>
          <Segment
            inverted={true}
            textAlign="center"
            style={{
              minHeight: "100vh",
              padding: "0em 0em",
            }}
            vertical
          >
            <HomepageHeading />
            <LazyLoadImage
              visibleByDefault
              alt="background-image"
              effect="blur"
              height="100%"
              width="100%"
              style={{
                display: "block",
                width: "100vw",
                height: "100vh",
                objectFit: "cover",
              }}
              placeholderSrc={backgroundImagePlaceholder}
              src={backgroundImage} // use normal <img> attributes as props
              wrapperProps={{ style: { display: "block" } }}
            />
            <Transition visible={true} animation="scale" duration={2000}>
              <StyledScrollIcon
                onClick={this.scrollToContent}
                name="angle down"
                size="massive"
                style={{ position: "absolute", bottom: "1rem" }}
              />
            </Transition>
          </Segment>
        </Desktop>
        <MaxTablet>
          <Segment
            inverted={true}
            textAlign="center"
            style={{
              minHeight: "100vh",
              padding: "0em 0em",
            }}
            vertical
          >
            <HomepageHeading mobile />
            <LazyLoadImage
              alt="background-image"
              effect="blur"
              height="100%"
              width="100%"
              style={{
                height: "100vh",
                objectFit: "none",
                objectPosition: "top",
              }}
              placeholderSrc={backgroundImagePlaceholder}
              src={backgroundImage} // use normal <img> attributes as props
            />
            <Transition visible={true} animation="scale" duration={2000}>
              <StyledScrollIcon
                onClick={this.scrollToContentMobile}
                name="angle down"
                size="massive"
                style={{ color: "#000", position: "absolute", bottom: "20vh" }}
              />
            </Transition>
          </Segment>
        </MaxTablet>

        <section
          className="blue"
          ref={(section) => {
            this.Blue = section;
          }}
          style={{ height: "10px" }}
        ></section>

        <Desktop>
          <Segment style={{ marginTop: "6em" }} vertical>
            <Grid container stackable verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={9}>
                  <StyledHeader as="h3">Welcome</StyledHeader>
                  <p style={{ fontSize: "1.33em" }}>
                    Welcome to my personal page! My name is Anton and I am an
                    engineer and developer with experience across several
                    industries and disciplines. On this website you can find
                    summaries of my previous work and current interests.
                  </p>
                  <StyledHeader as="h3">About Me</StyledHeader>
                  <p style={{ fontSize: "1.33em" }}>
                    Born and raised in the United States, I am currently based
                    in the Netherlands where I have obtained my degrees. I have
                    permanent residency in the Netherlands without any working
                    restrictions.
                  </p>
                  <StyledHeader as="h3">Career</StyledHeader>
                  <p style={{ fontSize: "1.33em" }}>
                    I am currently looking for full-time positions or free-lance
                    work. If you would like to get in touch, please feel free to
                    contact me!
                  </p>
                </Grid.Column>
                <Grid.Column floated="right" width={6}>
                  <Image bordered circular size="big" src={preach_image} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Button
                  as={Link}
                  to="/projects"
                  size="huge"
                  style={{
                    backgroundColor: "rgba(50,50,50)",
                    color: "rgba(242,242,255,0.65)",
                    width: "50%",
                    fontFamily: "Dosis",
                    fontSize: "2.1em",
                    margin: "2em auto",
                  }}
                >
                  Check out my projects
                </Button>
              </Grid.Row>
            </Grid>
          </Segment>
        </Desktop>

        <Tablet>
          <Container as={Segment} basic style={{ marginTop: "5em" }}>
            <StyledHeader as="h3">Welcome</StyledHeader>
            <Image
              circular
              src={preach_image}
              floated="right"
              style={{ maxWidth: "370px", marginLeft: "2em" }}
              // style={{ shapeOutside: 'circle()', maxWidth: '370px', marginLeft:'2em' }}
            />
            <p style={{ fontSize: "1.33em" }}>
              Welcome to my personal page! My name is Anton and I am an engineer
              and developer with experience across several industries and
              disciplines. On this website you can find summaries of my previous
              work and current interests.
            </p>
            <StyledHeader as="h3">About Me</StyledHeader>
            <p style={{ fontSize: "1.33em" }}>
              Born and raised in the United States, I am currently based in the
              Netherlands where I have obtained my degrees. I have permanent
              residency in the Netherlands without any working restrictions.
            </p>
            <StyledHeader as="h3">Career</StyledHeader>
            <p style={{ fontSize: "1.33em" }}>
              I am currently looking for full-time positions or free-lance work.
              If you would like to get in touch, please feel free to contact me!
            </p>
            <Segment basic textAlign="center">
              <Button
                as={Link}
                to="/projects"
                size="huge"
                style={{
                  backgroundColor: "rgba(50,50,50)",
                  color: "rgba(242,242,255,0.65)",
                  width: "100%",
                  fontFamily: "Dosis",
                  fontSize: "2.1em",
                  margin: "0 auto",
                }}
              >
                Check out my projects
              </Button>
            </Segment>
          </Container>
        </Tablet>

        <Mobile>
          <Container as={Segment} basic style={{ marginTop: "3em" }}>
            <Image circular size="medium" src={preach_image} centered />
            <StyledHeader as="h3">Welcome</StyledHeader>
            <p style={{ fontSize: "1.33em" }}>
              Welcome to my personal page! My name is Anton and I am an engineer
              and developer with experience across several industries and
              disciplines. On this website you can find summaries of my previous
              work and current interests.
            </p>
            <StyledHeader as="h3">About Me</StyledHeader>
            <p style={{ fontSize: "1.33em" }}>
              Born and raised in the United States, I am currently based in the
              Netherlands where I have obtained my degrees. I have permanent
              residency in the Netherlands without any working restrictions.
            </p>
            <StyledHeader as="h3">Career</StyledHeader>
            <p style={{ fontSize: "1.33em" }}>
              I am currently looking for full-time positions or free-lance work.
              If you would like to get in touch, please feel free to contact me!
            </p>

            <Segment basic textAlign="center">
              <Button
                as={Link}
                to="/projects"
                size="huge"
                style={{
                  width: "100%",
                  fontFamily: "Dosis",
                  fontSize: "2.1em",
                  margin: "0 auto",
                }}
              >
                Check out my projects
              </Button>
            </Segment>
          </Container>
        </Mobile>

        <Segment
          inverted={true}
          vertical
          style={{
            marginTop: "2cm",
            padding: "1em 0em",
            color: "rgba(255,255,255,0.5)",
          }}
        >
          <Grid container columns="equal" stackable>
            <Grid.Row textAlign="center">
              <Grid.Column style={{ paddingBottom: "3em", paddingTop: "3em" }}>
                <StyledHeader as="h3">Experience</StyledHeader>
                <div style={{ fontSize: "1.33em" }}>
                  <b>Front-end Engineer</b> <br /> Xeelas B.V.
                  <br />
                  <br />
                  <b>CTO & Developer</b> <br /> Shake-on B.V.
                  <br />
                  <br />
                  <b>Working Student</b> <br />
                  Heerema Marine Contractors
                  <br />
                  <br />
                  <b>Teaching Assistant</b>
                  <br />
                  Delft University of Technology
                  <br />
                  <br />
                  <b>Suspension Engineer</b>
                  <br />
                  Formula Student Team Delft
                  <br />
                  <br />
                </div>
              </Grid.Column>
              <Grid.Column style={{ paddingBottom: "3em", paddingTop: "3em" }}>
                <StyledHeader as="h3">Programming</StyledHeader>
                <div style={{ fontSize: "1.33em" }}>
                  React.JS / Node.JS / Express.JS
                  <br />
                  JavaScript (ES6) + HTML + CSS
                  <br />
                  Matlab + Simulink
                  <br />
                  Python
                  <br />
                </div>
                <StyledHeader as="h3">Engineering</StyledHeader>
                <p style={{ fontSize: "1.33em" }}>
                  <b>MSc Biomedical Engineering</b> <br />
                  <b>BSc Aerospace Engineering</b> <br />
                  <Icon disabled name="university" />
                  Technische Universiteit Delft <br />
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        <Projects homepage={true} />

        <Segment
          inverted={true}
          vertical
          style={{ marginTop: "2cm", padding: "5em 0em" }}
        >
          <Container>
            <Grid divided inverted={true} stackable>
              <Grid.Row>
                <Grid.Column width={16} textAlign="center">
                  <Header as="h4" inverted={true}>
                    Built by Anton de Bode
                  </Header>
                  <p>2020</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </div>
    );
  }
}

export default Main;
